<template>
  <div v-swiper:mySwiper="swiperOption" :class="$style.swiper">
    <div class="swiper-wrapper">
      <div v-for="(el, index) in data" :key="index" class="swiper-slide">
        <slot :el="el" :index="index" />
        <div v-if="lazy" class="swiper-lazy-preloader" />
      </div>
    </div>

    <!-- Add Pagination -->
    <div v-if="pagination" class="swiper-pagination"></div>

    <!-- Add Navigation -->
    <template v-if="navigation">
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
    initialSlide: { type: Number, default: 0 },
    speed: { type: Number, default: 300 },
    vertical: { type: Boolean, default: false },
    slidesPerView: { type: [Number, String], default: 1 },
    centeredSlides: { type: Boolean, default: false },
    simulateTouch: { type: Boolean, default: false },
    freeMode: { type: Boolean, default: false },
    loop: { type: Boolean, default: true },
    navigation: { type: [Boolean, Object], default: false },
    pagination: { type: [Boolean, Object], default: false },
    autoplay: { type: [Boolean, Object], default: false },
    lazy: { type: Boolean, default: false },
    fade: { type: Boolean, default: false },
    slideChange: {
      type: Function,
      default() {
        return null
      },
    },
  },
  computed: {
    swiperOption() {
      return {
        /**
         * Parameters
         * https://swiperjs.com/api/#parameters
         */
        ...{
          initialSlide: this.initialSlide,
          speed: this.speed,
          direction: this.vertical ? 'vertical' : 'horizontal',
          // Slides grid
          slidesPerView: this.slidesPerView,
          centeredSlides: this.centeredSlides,
          // Touches
          simulateTouch: this.simulateTouch,
          // Freemode
          freeMode: this.freeMode,
          // Loop
          // 淡入特效不需要进行循环
          loop: this.fade ? false : this.loop,
        },

        /**
         * Navigation
         * https://swiperjs.com/api/#navigation
         */
        ...this.navigationOption,

        /**
         * Pagination
         * https://swiperjs.com/api/#pagination
         */
        ...this.paginationOption,

        /**
         * Autoplay
         * https://swiperjs.com/api/#autoplay
         */
        ...this.autoplayOption,

        /**
         * Lazy Loading
         * https://swiperjs.com/api/#lazy
         */
        ...(this.lazy ? { lazy: this.lazy } : {}),

        /**
         * Fade Effect
         * https://swiperjs.com/api/#fade-effect
         */
        ...this.fadeOption,
      }
    },
    navigationOption() {
      if (this.navigation) {
        return {
          navigation: {
            ...(typeof this.navigation === 'object' ? this.navigation : {}),
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        }
      }
      return {}
    },
    paginationOption() {
      if (this.pagination) {
        return {
          pagination: {
            clickable: true,
            ...(typeof this.pagination === 'object' ? this.pagination : {}),
            el: '.swiper-pagination',
          },
        }
      }
      return {}
    },
    autoplayOption() {
      if (this.autoplay) {
        return {
          autoplay: {
            disableOnInteraction: false,
            ...(typeof this.autoplay === 'object' ? this.autoplay : {}),
          },
        }
      }
      return {}
    },
    fadeOption() {
      if (this.fade) {
        return {
          effect: 'fade',
          fadeEffect: { crossFade: true },
        }
      }
      return {}
    },
  },
  mounted() {
    if (this.slideChange) {
      this.mySwiper.on('slideChange', () => {
        this.slideChange(this.mySwiper)
      })
    }
  },
  methods: {
    slideTo(index, speed) {
      this.mySwiper.slideTo(index, speed)
    },
    slideNext() {
      this.mySwiper.slideNext()
    },
    slidePrev() {
      this.mySwiper.slidePrev()
    },
  },
}
</script>

<style lang="less" module>
.swiper {
  position: relative;
}
</style>
