<template>
  <div :class="$style.overview">
    <base-swiper
      v-if="banners.length > 0"
      :data="banners"
      vertical
      :pagination="banners.length > 1 && pagination"
      :autoplay="autoplay"
      lazy
      fade
      :class="[$style.swiper, 'swiper-container-vertical']"
    >
      <template v-slot:default="slotProps">
        <a v-if="slotProps.el.url" :href="slotProps.el.url" target="_blank">
          <the-overview-item :data="slotProps" />
        </a>
        <the-overview-item v-else :data="slotProps" />
      </template>
    </base-swiper>
  </div>
</template>

<script>
import BaseSwiper from '../BaseSwiper'
import TheOverviewItem from './TheOverviewItem'

export default {
  components: { BaseSwiper, TheOverviewItem },
  props: {
    banners: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      autoplay: { delay: 4000 },
      pagination: {
        bulletClass: this.$style.bullet,
        bulletActiveClass: this.$style.bullet_active,
      },
    }
  },
}
</script>

<style lang="less" module>
.overview {
  height: 11.05rem;

  @media screen and (max-width: @mini-width) {
    height: 16.05rem;
  }
}

.swiper {
  .size(100%);

  :global {
    .swiper-slide {
      background-color: #fff;
    }

    .swiper-pagination {
      right: 0.42rem;
    }
  }
}

.bullet {
  @bullet-base-size: 0.22rem;
  @bullet-small-size: 0.08rem;
  @bullet-bar-size: 0.72rem;
  @bullet-color: #fff;
  @bullet-radius: 0.22rem;

  @tablet-bullet-base-size: 22px;
  @tablet-bullet-small-size: 8px;
  @tablet-bullet-bar-size: 72px;

  .size(@bullet-base-size);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.02rem auto;
  border-radius: 100%;
  transition: height 0.3s;
  cursor: pointer;

  @media screen and (max-width: @tablet-width) {
    .size(@tablet-bullet-base-size);
  }

  &::after {
    .size(@bullet-small-size);
    content: '';
    display: block;
    border-radius: 100%;
    background-color: @bullet-color;

    @media screen and (max-width: @tablet-width) {
      .size(@tablet-bullet-small-size);
    }
  }

  &:hover:not(&_active) {
    background-color: fade(@bullet-color, 80%);

    &::after {
      background-color: @primary;
    }
  }

  &_active {
    .size(@bullet-small-size, @bullet-bar-size);
    align-items: flex-start;
    border-radius: @bullet-radius;
    background-color: fade(@bullet-color, 50%);

    @media screen and (max-width: @tablet-width) {
      .size(@tablet-bullet-small-size, @tablet-bullet-bar-size);
    }

    &::after {
      .size(100%);
      border-radius: @bullet-radius;
      animation: bulletbar 4.3s linear; // + 0.3s 切换动画延迟
    }
  }

  @keyframes bulletbar {
    from {
      height: @bullet-small-size;
    }
    to {
      height: @bullet-bar-size;
    }
  }

  @media screen and (max-width: @tablet-width) {
    @keyframes bulletbar {
      from {
        height: @tablet-bullet-small-size;
      }
      to {
        height: @tablet-bullet-bar-size;
      }
    }
  }
}
</style>
