<template>
  <div :class="$style.menu">
    <a
      v-if="data.link"
      :href="system[data.link] && system[data.link].url"
      target="_blank"
    >
      <the-entry-menu-cont :data="data" />
    </a>
    <nuxt-link v-else-if="data.url" :to="data.url">
      <the-entry-menu-cont :data="data" />
    </nuxt-link>
    <the-entry-menu-cont v-else :data="data" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheEntryMenuCont from './TheEntryMenuCont'

export default {
  components: { TheEntryMenuCont },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...mapGetters(['system']),
  },
}
</script>

<style lang="less" module>
.menu {
  width: 1.33rem;

  @media screen and (max-width: @mini-width) {
    width: auto;
  }

  a {
    text-decoration: none;
  }
}
</style>
