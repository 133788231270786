<template>
  <div :class="gray && $style.entry_gray">
    <div :class="$style.entry_wrap">
      <the-entry-menu v-for="(menu, index) in list" :key="index" :data="menu" />
    </div>
  </div>
</template>

<script>
import entryData from './entryData'
import TheEntryMenu from './TheEntryMenu'

export default {
  components: { TheEntryMenu },
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
    gray: { type: Boolean, default: false },
  },
  computed: {
    list() {
      return this.data.map((name) => {
        return entryData[name]
      })
    },
  },
}
</script>

<style lang="less" module>
.entry {
  &_gray {
    background-color: #fbfbfb;
  }

  &_wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 3.6rem;
    padding: 0.56rem 0;

    @media screen and (max-width: @tablet-width) {
      margin: 0 3rem;
    }

    @media screen and (max-width: @mini-width) {
      margin: 0 2rem;
    }

    @media screen and (max-width: @mobile-width) {
      margin: 0 1rem;
    }
  }
}
</style>
