<template>
  <div id="js-game-list">
    <div id="js-game-list-wrap" :class="$style.games">
      <h2 id="js-game-list-title" :class="$style.title">
        <span class="app-fadeInUp">旗下游戏</span>
      </h2>
      <div id="js-game-list-content">
        <div class="app-fadeInUp">
          <base-swiper
            v-if="games.length > 0"
            ref="swiper"
            :data="games"
            :initial-slide="initialSlide"
            :slides-per-view="5"
            centered-slides
            simulate-touch
            :class="$style.swiper"
          >
            <template v-slot:default="slotProps">
              <the-game-list-item :data="slotProps.el" />
            </template>
          </base-swiper>
          <div :class="$style.navigation">
            <button :class="$style.prev" @click="onClick('prev')" />
            <button :class="$style.next" @click="onClick('next')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from './mixins'
import { mapGetters } from 'vuex'
import BaseSwiper from '../BaseSwiper'
import TheGameListItem from './TheGameListItem'

export default {
  components: { BaseSwiper, TheGameListItem },
  mixins: [mixins],
  data() {
    return {
      name: 'game-list',
      animateScenesData: ['title', 'content'],
      parallaxTriggerHook: 1,
    }
  },
  computed: {
    ...mapGetters(['games']),
    initialSlide() {
      return this.games.findIndex((el) => el.main_flag)
    },
  },
  methods: {
    onClick(type) {
      switch (type) {
        case 'prev':
          this.$refs.swiper.slidePrev()
          break
        case 'next':
          this.$refs.swiper.slideNext()
          break
      }
    },
  },
}
</script>

<style lang="less" module>
.games {
  z-index: 1;
  position: relative;
  padding: @padding-base-title 0;
  background-color: #f7f7f7;

  h2 {
    margin-bottom: @padding-title-bot;
    text-align: center;

    span {
      .subtitle-size(1.67rem, 0.61rem);
      .text-hide();
      display: inline-block;
      vertical-align: top;
      background: url('./images/game-list-title.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}

.swiper {
  @swiper-button-margin: 8;

  height: 5.93rem;
  margin: 0 0.35rem 0 0.75rem;

  :global {
    .swiper-button-prev,
    .swiper-button-next {
      .size(0.26rem, 0.27rem);
      top: auto;
      bottom: 0;
      margin-top: 0;
      background: url('./images/game-list-arrow.png') no-repeat;
      background-size: 200% 200%;

      @media screen and (max-width: @laptop-width) {
        .size(20px, 21px);
      }

      &:hover {
        background-color: @primary;
      }

      &::after {
        content: none;
      }
    }

    .swiper-button-prev {
      @left: 0.26rem + @swiper-button-margin;

      left: @left;
      background-position: 0 0;

      @media screen and (max-width: @mini-width) {
        left: @left - 0.5;
      }

      &:hover {
        background-position: 0 100%;
      }
    }

    .swiper-button-next {
      @right: 0.65rem + @swiper-button-margin;

      right: @right;
      background-position: 100% 0;

      @media screen and (max-width: @mini-width) {
        right: @right - 0.5;
      }

      &:hover {
        background-position: 100% 100%;
      }
    }
  }
}

.navigation {
  text-align: center;

  button {
    .size(0.26rem, 0.27rem);
    margin: 0 0.22rem;
    background-color: transparent;
    background-image: url('./images/game-list-arrow.png');
    background-repeat: no-repeat;
    background-size: 200% 200%;

    @media screen and (max-width: @laptop-width) {
      .size(20px, 21px);
    }

    &:hover {
      background-color: @primary;
    }
  }

  .prev {
    background-position: 0 0;

    &:hover {
      background-position: 0 100%;
    }
  }

  .next {
    background-position: 100% 0;

    &:hover {
      background-position: 100% 100%;
    }
  }
}
</style>
