<template>
  <div
    :class="[
      data.icon && $style.icon,
      data.icon && $style[`icon_${data.icon}`],
    ]"
  >
    <div v-if="data.type === 'qrc'" :class="$style.qrc">
      <img :src="qrc" />
    </div>
    <div v-if="data.type !== 'qrc'" :class="[$style.title]">
      {{ data.title }}
      <span>&gt;</span>
    </div>
    <div
      :class="$style.desc"
      v-html="
        data.type === 'contact'
          ? `${
              contact.phone_cs ? `客服专线：${contact.phone_cs}<br />` : ''
            }公司电话：${contact.phone || ''}`
          : data.desc
      "
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...mapGetters(['qrc', 'contact']),
  },
}
</script>

<style lang="less" module>
.icon {
  @icon-width: 0.44rem;
  @icon-height: 0.4rem;
  @icon-number: 12;

  &::before {
    .size(@icon-width, @icon-height);
    content: '';
    display: block;
    margin-bottom: 0.2rem;
    background: url('./images/entry-icon.png') no-repeat;
    background-size: 100% * @icon-number 200%;
    transition: transform 0.3s;

    a:hover & {
      transform: translateY(-0.07rem);
    }
  }

  .loop(@n) when (@n <= @icon-number) {
    &_@{n}::before {
      .sprites-position-x(@icon-width * @icon-number, @icon-width, @icon-width * (@n - 1));

      a:hover & {
        .sprites-position-x(@icon-width * @icon-number, @icon-width, @icon-width * (@n - 1), 100%);
      }
    }
    .loop(@n + 1);
  }
  .loop(1);
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.07rem;
  padding-bottom: 0.1rem;
  border-bottom: 0.01rem solid #c0c0c0;
  white-space: nowrap;
  font-size: 0.18rem;
  color: #222;

  span {
    margin-left: 1em;
    font-size: 0.16rem;
    color: #bfbfbf;
  }
}

.desc {
  font-size: 12px;
  line-height: 1.5;
  color: #959595;
  white-space: nowrap;
}

.qrc {
  border-bottom: 0.01rem solid #c0c0c0;
  margin-bottom: 0.07rem;

  img {
    .size(0.91rem);
    transform: translateY(-0.1rem);
  }
}

@media screen and (max-width: @tablet-width) {
  .icon {
    &::before {
      .size(32px, 29px);
    }
  }

  .title {
    font-size: 14px;

    span {
      font-size: 12px;
    }
  }
  .qrc {
    img {
      .size(61px);
    }
  }
}

@media screen and (max-width: @mini-width) {
  .title {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  .desc {
    display: none;
  }

  .qrc {
    border-bottom: 0;
    margin-bottom: 0;

    img {
      .size(56px);
    }
  }
}

@media screen and (max-width: @mobile-width) {
  .icon {
    &::before {
      margin-bottom: 0.4rem;
    }
  }

  .title {
    font-size: 12px;
  }
}
</style>
