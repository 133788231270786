/**
 * copy from aboutMixins
 * @desc 区域的视差(Tween)会导致游戏列表字体显示模糊, 只能暂时去掉
 * @param {string} name - 组件名称
 * @param {Array} animateScenesData - 动画组件列表
 * @param {number} parallaxTriggerHook - 视差效果触发构子
 */
export default {
  data() {
    return {
      parallaxScene: null,
      animateScenes: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      /**
       * Add parallax scene
       */
      this.parallaxScene = this.$scrollmagic.scene({
        triggerElement: `#js-${this.name}`,
        duration: 300,
        triggerHook: this.parallaxTriggerHook || 0.5,
      })
      // .setTween(`#js-${this.name}-wrap`, { y: '-8%' })

      // indicator
      process.env.NODE_ENV === 'development' &&
        this.parallaxScene.addIndicators({ name: `${this.name} parallax` })

      this.$scrollmagic.addScene(this.parallaxScene)

      /**
       * Add animate scene
       */
      this.animateScenes = this.animateScenesData.map((el) => {
        let title, direction

        if (typeof el === 'string') {
          title = el
          direction = 'Up'
        } else {
          title = el.title
          direction = el.direction
        }

        const scene = this.$scrollmagic
          .scene({
            triggerElement: `#js-${this.name}-${title}`,
            triggerHook: 1,
          })
          .setClassToggle(
            `#js-${this.name}-${title} .app-fadeIn${direction}`,
            `app-fadeIn${direction}-active`
          )

        // indicator
        process.env.NODE_ENV === 'development' &&
          scene.addIndicators({ name: `${this.name} ${title} animate` })

        this.$scrollmagic.addScene(scene)

        return scene
      })
    })
  },
  destroyed() {
    /**
     * Destroy parallax scene
     */
    this.parallaxScene && this.parallaxScene.destroy(true)

    /**
     * Destroy animate scene
     */
    if (this.animateScenes.length > 0) {
      this.animateScenes.forEach((el) => {
        el.destroy(true)
      })
    }
  },
}
