<template>
  <a :href="data.url || 'javascript:;'" target="_blank" :class="$style.game">
    <img :src="data.banner" :alt="data.title" :class="$style.poster" />
    <div :class="$style.title">{{ data.title }}</div>
    <div :class="$style.brand">
      <div :class="$style.brand_subtitle">
        <span>{{ data.subtitle }}</span>
      </div>
      <div :class="$style.brand_title">
        <span>{{ data.title }}</span>
      </div>
      <div :class="$style.brand_home"><span>进入官网</span></div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style lang="less" module>
@transition-delay: 0.2s;

.poster {
  .size(2.7rem,4.92rem);
  position: absolute;
  top: 0.01rem;
  left: 0.26rem;
  transition: box-shadow @transition-delay;

  .game:hover &,
  :global(.swiper-slide-active) & {
    box-shadow: 0.13rem 0.13rem 0.11rem fade(#110509, 15%);
  }

  @media screen and (max-width: @mini-width) {
    :global(.swiper-slide-active) & {
      box-shadow: none;
    }
  }
}

.title {
  position: absolute;
  top: 5rem;
  left: 0.25rem;
  font-size: 16px;
  color: @text-color;
  white-space: nowrap;
  transition: opacity @transition-delay;

  @media screen and (max-width: @laptop-width) {
    font-size: 14px;
  }

  .game:hover &,
  :global(.swiper-slide-active) & {
    opacity: 0;
  }

  @media screen and (max-width: @mini-width) {
    .game:hover &,
    :global(.swiper-slide-active) & {
      opacity: 1;
    }
  }
}

.brand {
  .size(3.2rem, 2.16rem);
  position: absolute;
  top: 3.3rem;
  left: 0.11rem;
  padding-left: 0.75rem;
  background: url('./images/game-list-brand.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity @transition-delay;

  @media screen and (max-width: @mini-width) {
    display: none;
  }

  .game:hover &,
  :global(.swiper-slide-active) & {
    opacity: 1;
  }

  > div {
    overflow: hidden;
  }

  span {
    display: block;
    transform: translateY(1em);
    transition: transform @transition-delay;

    .game:hover &,
    :global(.swiper-slide-active) & {
      transform: translateY(0);
    }

    :global(.swiper-slide-active) .game:hover & {
      animation: in 0.3s;
    }

    @keyframes in {
      from {
        transform: translateY(100%);
      }
      to {
        transform: translateY(0);
      }
    }
  }

  &_subtitle {
    height: 0.73rem;
    padding-top: 0.5rem;
    box-sizing: border-box;

    @media screen and (max-width: @laptop-width) {
      font-size: 12px;
    }

    @media screen and (max-width: @tablet-width) {
      display: none;
    }
  }

  &_title {
    font-size: 0.36rem;
    text-shadow: 0.06rem 0.06rem 0.02rem fade(#110509, 10%);

    @media screen and (max-width: @tablet-width) {
      padding-top: 0.5rem;
    }
  }

  &_home {
    margin-top: 0.54rem;

    @media screen and (max-width: @laptop-width) {
      margin-top: 0.44rem;
    }

    span {
      display: flex;
      align-items: center;

      &::before {
        .size(8px, 11px);
        content: '';
        display: block;
        margin-right: 0.5em;
        background: url('./images/game-list-icon.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
