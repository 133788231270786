export default {
  about: {
    url: '/about',
    icon: 1,
    title: '关于我们',
    desc: '为用户创造美好体验',
  },
  join: {
    link: 'job',
    icon: 2,
    title: '加入我们',
    desc: '一切为了寻找极致的你',
  },
  contact: {
    type: 'contact',
    url: '/contact',
    icon: 3,
    title: '联系我们',
  },
  like: {
    type: 'qrc',
    title: '关注我们',
    desc: '扫码关注了解更多资讯',
  },
  honor: {
    url: '/honor',
    icon: 4,
    title: '极致荣誉',
    desc: '我们所获得的荣誉',
  },
  business: {
    url: '/business',
    icon: 5,
    title: '商务合作',
    desc: '欢迎咨询洽谈各项合作',
  },
  game: {
    url: '/game',
    icon: 6,
    title: '极致产品',
    desc: '查看极致所有产品',
  },
  news: {
    url: '/news',
    icon: 7,
    title: '极致资讯',
    desc: '随时获取极致新闻信息',
  },
  develop: {
    url: '/develop',
    icon: 8,
    title: '人才发展',
    desc: '一切为了成就极致的你',
  },
  benefit: {
    url: '/benefit',
    icon: 9,
    title: '福利体系',
    desc: '一切为了服务极致的你',
  },
  life: {
    url: '/life',
    icon: 10,
    title: '极致生活',
    desc: '希望是你期待的极致一天',
  },
  society: {
    link: 'job',
    icon: 11,
    title: '社会招聘',
    desc: '一切为了寻找极致的你',
  },
  campus: {
    link: 'campus',
    icon: 12,
    title: '校园招聘',
    desc: '极所能，致梦想',
  },
}
