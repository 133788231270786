<template>
  <div>
    <the-overview :banners="banners" />
    <the-game-list />
    <the-entry :data="entry" />
  </div>
</template>

<script>
import TheOverview from '@/components/TheOverview'
import TheGameList from '@/components/TheGameList'
import TheEntry from '@/components/TheEntry'

export default {
  components: { TheOverview, TheGameList, TheEntry },
  async asyncData({ app, $winstonLog }) {
    try {
      const banners = await app.$axios.get('/wbhome/mainPageBanner')
      return { banners: (banners.data && banners.data.data) || [] }
    } catch ({ response }) {
      const { status, statusText, config } = response || {}
      $winstonLog.error(`${new Date()} ${config.url} ${status} ${statusText}`)
      return { banners: [] }
    }
  },
  data() {
    return { entry: ['about', 'join', 'contact', 'like'] }
  },
  middleware: 'games',
}
</script>
