<template>
  <div :class="$style.item">
    <img :data-src="data.el.thumb" :alt="data.el.title" class="swiper-lazy" />
    <span :class="numClass(data.index + 1)" />
    <span v-if="data.el && data.el.url" :class="$style.detail">查看详情</span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    numClass(num) {
      let tens = 0
      let ones = num

      if (num > 9) {
        num = String(num).split('')
        tens = num[0]
        ones = num[1]
      }

      return [
        this.$style.num,
        this.$style[`num_tens_${tens}`],
        this.$style[`num_ones_${ones}`],
      ]
    },
  },
}
</script>

<style lang="less" module>
.item {
  position: relative;

  &,
  img {
    .size(100%);
  }

  @media screen and (max-width: @mini-width) {
    img {
      width: auto;
      margin-left: -8.69rem;
    }
  }
}

.detail {
  position: absolute;
  top: 6.45rem;
  left: 1.53rem;
  width: 1.56rem;
  line-height: 0.71rem;
  background: url('./images/overview-detail.png') no-repeat;
  background-size: 100% 200%;
  color: #fff;
  text-align: center;

  @media screen and (max-width: @tablet-width) {
    font-size: 12px;
  }

  @media screen and (max-width: @tablet-width) {
    width: 88px;
    line-height: 40px;
  }

  @media screen and (max-width: @mini-width) {
    display: none;
  }

  &:hover {
    background-position: 0 100%;
  }
}

.num {
  @num-width: 0.56rem;
  @num-height: 0.84rem;

  position: absolute;
  top: 3.56rem;
  left: 1.38rem;
  display: flex;
  padding-left: 0.2rem;
  background: url('./images/overview-sign.png') no-repeat;
  background-size: 0.12rem 0.15rem;

  @media screen and (max-width: @mini-width) {
    display: none;
  }

  &::before,
  &::after {
    .size(@num-width,@num-height);
    content: '';
    display: block;
    background: url('./images/overview-number.png') no-repeat;
    background-size: 1000% 100%;
  }

  .loop(@n) when (@n < 10) {
    &_tens_@{n}::before,
    &_ones_@{n}::after {
      .sprites-position-x(@num-width * 10, @num-width, @num-width * @n);
    }
    .loop(@n + 1);
  }
  .loop(0);
}
</style>
